/* eslint-disable no-empty-function */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable max-len, max-lines, no-console, no-return-assign, class-methods-use-this, max-statements */
/* globals mapboxgl, MapboxGeocoder */
import "./style.scss";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import React from "react";
import MikmakBlock from "../mikmak";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";

import barLogo from 'global/images/the-bar-logo.svg'

class WhereToBuySection extends React.Component {

    constructor (props) {

        super(props);
        this.findLink = "";

    }

	 whereToBuyFindNow () {


        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            dimension14: "Captain Morgan",
            event: "customEvent",
            eventAction: "Find now",
            eventCategory: "WTB_Find_Near_Me",
            eventInteraction: true,
            eventLabel: "Captain Morgan",


        });


    }

    whereToBuyFindOnline () {


        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            dimension14: "Captain Morgan",
            event: "customEvent",
            eventAction: "Shop Online",
            eventCategory: "WTB_Shop_Online",
            eventInteraction: true,
            eventLabel: "Captain Morgan",


        });


    }

    componentDidMount () {
      const locationDiv = document.getElementById('location')
      
      if  (locationDiv) {
        const geocoder = new MapboxGeocoder({
          accessToken: "pk.eyJ1IjoibXdrcmllZ2VyIiwiYSI6ImNqcDA1NGQ4bDA0cjIzd3BpbGl5NzVvcXMifQ.2Cs5UE0BiZsh_wt36xHRoA",
          countries: "us",
          placeholder: "Enter Location",
          types: "postcode,address",
        });
        geocoder.addTo("#location");
        geocoder.on("result", (event) => {

            this.findLink = event.result;
            console.log(event);

        });
      }
    }

    handleSearch () {

        this.whereToBuyFindNow();
        //Console.log(this.findLink);
        if (this.findLink) {

            window.location.href = `/en-us/where-to-buy/find-near-me?lat=${this.findLink.center[1]}&lng=${this.findLink.center[0]}`;

        } else {

            window.location.href = "/en-us/where-to-buy/find-near-me";

        }

    }

  render () {
    let showMikmak = false
    const mikmakData = fieldsArrayToKeyValueHash(this.props.fields.filter((element) => element.title === "Mikmak"));
    if (mikmakData && mikmakData.Mikmak && mikmakData.Mikmak[0] && mikmakData.Mikmak[0].fields && mikmakData.Mikmak[0].fields.length > 0) {
      showMikmak = true
    }
    // console.log('showMikmak > ', showMikmak)
    // console.log('mikmakData > ', mikmakData)
    return (
  	<>
      {!showMikmak ? (
        <>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css" integrity="sha512-iBBXm8fW90+nuLcSKlbmrPcLa0OT92xO1BIsZ+ywDWZCvqsWgccV3gFoRBv0z+8dLJgyAHIhR35VZc2oM/gI1w==" crossOrigin="anonymous"/>
          <script src="https://api.mapbox.com/mapbox-gl-js/v1.11.1/mapbox-gl.js"/>
          <link href="https://api.mapbox.com/mapbox-gl-js/v1.11.1/mapbox-gl.css" rel="stylesheet"/>
          <link rel="stylesheet" href="https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v4.5.1/mapbox-gl-geocoder.css" type="text/css"/>
          <script src="https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v4.5.1/mapbox-gl-geocoder.min.js" />
        </>
        ) : null}
      <header className="where-to-buy__hero">
          <div className="where-to-buy__hero-content">
              <h1 className="where-to-buy__hero-title gold_font">Where To Buy</h1>
          </div>
      </header>
    	<div className="Wrapper">
      {showMikmak ? (
          <>
            <MikmakBlock {...mikmakData.Mikmak[0]} />
          </>
        ) : (
          <>
            <div className="leftPanel">
              <div className="inner">

                <div className="heading">
                  <h2>Find Near Me</h2>
                </div>
                <div className="icon">
                  <div className="icon">
                    <i className="fas fa-map-marker-alt"></i>
                  </div>
                </div>

                <div className="copy">
                  <p>
                      Use our store locator to find what's available at a retailer or restaurant/bar near you
                  </p>
                </div>

                <div className="formWrapper">
                  <div id="location" className="textInput" placeholder="ENTER LOCATION"/>
                  <a href="#" onClick={() => this.handleSearch()} className="cta">Find Now</a>
                </div>

              </div>
            </div>

            <div className="rightPanel">
              <div className="inner">
                <div className="heading">
                  <h2>Shop Online</h2>
                </div>
                <div className="icon">
                  <i className="fas fa-shopping-cart"></i>
                </div>
                <div className="copy">
                  <p>
                    {this.props.rightCopy}
                  </p>
                </div>

                <div className="linkWrapper">
                  <a className="cta" onClick={() => this.whereToBuyFindOnline()} href="/en-us/where-to-buy/shop-online">Shop Online</a>
                   <a className="cta bar-cta" target="_blank" href="https://us.thebar.com/rum/captain-morgan/">Buy at <img src={barLogo} alt="The Bar" /></a>
                </div>
              </div>
            </div>
          </>
        )}
    	</div>
  	</>
    );

  }

}

export default WhereToBuySection;
