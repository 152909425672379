import "./style.scss";
import React from "react";
import {Helmet} from 'react-helmet'
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";
import sanitise from "global/utils/sanitise";


const cn = require('classnames')

const MikmakBlock = (props) => {
  if (props && props.fields) {
    const {
      MikmakConfigurationID,
      MikmakCopy,
      MikmakEan,
      MikmakLabel,
      MikmakType,
    } = fieldsArrayToKeyValueHash(props.fields);

    return (
      <>
        <Helmet>
          <script
            id="mikmakscript"
            type="text/javascript"
            async
          >
            {`(function(e,d){try{var a=window.swnDataLayer=window.swnDataLayer||{};a.appId=e||a.appId,a.eventBuffer=a.eventBuffer||[],a.loadBuffer=a.loadBuffer||[],a.push=a.push||function(e){a.eventBuffer.push(e)},a.load=a.load||function(e){a.loadBuffer.push(e)},a.dnt=a.dnt!=null?a.dnt:d;var t=document.getElementsByTagName("script")[0],n=document.createElement("script");n.async=!0,n.src="//wtb-tag.mikmak.ai/scripts/"+a.appId+"/tag.min.js",t.parentNode.insertBefore(n,t)}catch(e){console.log(e)}}("66723359cfa99b31c6a41ab9", false));`}
          </script>
        </Helmet>

        {MikmakType === 'Navigation Item' &&
          <ul
            className="navigation__actions"
            role="menu"
          >
            <li role="none" className="navigation__actions-item">
              <button
                className="navigation__actions--buy"
                data-mm-ids={MikmakEan}
                data-mm-wtbid={MikmakConfigurationID}
                role="menuitem"
                type="button"
              >
                {MikmakLabel}
              </button>
            </li>
          </ul>
        }

        {MikmakType === 'Button' &&
          <button
            className="cta cta-product-promo"
            data-mm-ids={MikmakEan}
            data-mm-wtbid={MikmakConfigurationID}
            type="button"
          >
            {MikmakLabel}
          </button>
        }

        {MikmakType === 'In Page' &&
          <div className={`mikmak__inpage`} id="mikmak_wrapper">
            <div className="mikmak__heading ">
              {MikmakLabel &&
                <h2 className="mikmak__label">{MikmakLabel}</h2>
              }
              {MikmakCopy ? (
                <div
                  className="mikmak__copy text-center"
                  dangerouslySetInnerHTML={{
                    __html: MikmakCopy,
                  }}
                />
              ) : null}
            </div>
            <div
              className="mikmak__wrap"
              data-mm-ids={MikmakEan}
              data-mm-wtbid={MikmakConfigurationID}
              id="mikmak"
            />
          </div>
        }
      </>
    );

  }

  return false;
};

export default MikmakBlock;
